header {
  padding: 15px 0;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .logo {
    display: block;
    margin: auto;
    max-width: 300px;
  }

  a:hover {
    text-decoration: none;
  }
}